import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
// import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import Layout from "../../../src/components/modules/Layout"
// import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Seo"
import BlockWrapper from "../../../src/components/technical/BlockWrapper"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { ChevronLeft20 } from "@carbon/icons-react"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"

const JobTemplate = ({ pageContext }) => {
  const jobId = pageContext.jobId
  const subcompany = pageContext.subcompany
  const department = pageContext.department
  const employmentType = pageContext.employmentType
  const jobDescriptions = pageContext.jobDescriptions
  const companyDescription = pageContext.companyDescription
  const name = pageContext.name
  const occupation = pageContext.occupation
  const occupationCategory = pageContext.occupationCategory
  const office = pageContext.office
  const recruitingCategory = pageContext.recruitingCategory
  const schedule = pageContext.schedule
  const seniority = pageContext.seniority
  const yearsOfExperience = pageContext.yearsOfExperience

  const jobApplicationUrl = pageContext.jobApplicationUrl

  const seoJobPostingData = `{
    "@context" : "https://schema.org/",
    "@type" : "JobPosting",
    "title" : "${name}",
    "jobLocationType" : "${office}",
    "employmentType" : "${schedule}",
    "hiringOrganization" : {
      "@type" : "Organization",
      "name" : "${subcompany}"
    }
  }`

  return (
    <Layout language="de">
      <SEO
        title={`${name}`}
        description={`${name}`}
        jobPosting={seoJobPostingData}
      />
      <BlockWrapper blockPadding="" block={{}}>
        <div className="block mb-2 -ml-2 text-sm leading-6">
          <Link
            link="#"
            onClick={() => navigate(-1)}
            className="px-2 text-gray-400 hover:underline"
          >
            <ChevronLeft20 className="inline-block align-middle -mt-0.5 -ml-0.5 mr-1" />
            Alle Stellen anzeigen
          </Link>
        </div>
      </BlockWrapper>
      <BlockWrapper blockPadding="pb-block pt-10" block={{}}>
        <Headline headlineLevel="h1" headline={name} />
        <div className="w-full mt-8 prose lg:w-1/2 max-w-none">
          {jobDescriptions.jobDescription.map((jobDescription, index) => (
            <React.Fragment key={index}>
              <h3 className="headline headline-h4">{jobDescription.name}</h3>
              <div dangerouslySetInnerHTML={{ __html: jobDescription.value }} />
            </React.Fragment>
          ))}
        </div>

        <br />
        <Headline headlineLevel="h4" headline="Über RUKO" />
        <div className="w-full mt-8 prose lg:w-1/2 max-w-none">
          <p>
            Die RUKO GmbH Präzisionswerkzeuge ist ein dynamisches und
            innovatives Unternehmen, spezialisiert auf die Herstellung und den
            Vertrieb von Bohr-, Senk- und Schneidwerkzeugen für die
            Metallzerspanung.
          </p>
          <p>
            Mit ihrem Sitz in Deutschland und einer beeindruckenden Geschichte,
            die über 50 Jahre zurückreicht, hat sich RUKO zu einem führenden
            Anbieter in der Werkzeugindustrie entwickelt. RUKO zeichnet sich
            durch ständige Innovation, erstklassige Qualität und eigener
            Fertigung aus. Das moderne Fertigungszentrum mit starkem Fokus auf
            Entwicklung garantiert exzellente Produkte und macht RUKO zur ersten
            Wahl für Profis mit anspruchsvollen Anforderungen.
          </p>
        </div>

        <Link className="mt-8 btn btn-primary" link={jobApplicationUrl}>
          Jetzt bewerben
        </Link>
      </BlockWrapper>
    </Layout>
  )
}

export default JobTemplate
